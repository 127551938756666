import React from 'react'
import './PolycameraPage.scss'


import IMGPolycameraX8 from './image/polycamera_x8_img.png';
import IMGAiorport from './image/airport-img.png';
import IMGVideoSendor from './image/videosensor_img.png';
import IMGlenses from './image/lenses_img.png';
import IMGHdrDay from './image/hdr-day_img.png';
import IMGHdrTwilight from './image/hdr-twilight_img.png';
import IMGHdrNight from './image/hdr-night_img.png';
import IMGOperators from './image/camera-control-operators_img.png';
import IMGArchiveVideo from './image/archive-video_img.png';
import IMGPolycameraRender from './image/polycamera-render_img.png';
import IMGPolycameraProduct from './image/polycamera-product_img.png';

import {ReactComponent as SVGDownload} from './image/download_img.svg';
import {ReactComponent as SVGBell} from './image/bell-circle.svg';
import {ReactComponent as SVGStar} from './image/star-circle.svg';
import {ReactComponent as SVGGroup} from './image/group-circle.svg';
import IMGEllipse from "./image/ellipse_img.png";
import IMGGroup from "./image/group.png";
import IMGGroup2 from "./image/group2.png";

const PolycameraPage = () => {
    return (
        <div className="content">
            <img
                src={IMGEllipse}
                alt=""
                className='ellipse ellipse-5'
            />
            <img
                src={IMGEllipse}
                alt=""
                className='ellipse ellipse-6'
            />
            <img
                src={IMGEllipse}
                alt=""
                className='ellipse ellipse-7'
            />
            <img
                src={IMGEllipse}
                alt=""
                className='ellipse ellipse-4'
            />

            <div className='content__window content__window__main polycamera'>
                <div className="content__window-text content__window__main-text polycamera-text">
                    <h2>Polycamera <span>X8</span></h2>
                    <div className='content__window-desc content__window__main-desc polycamera-desc'>
                        <span>Наблюдения за большими площадями из единой точки, с высоким качеством изображения и низкими затратами на инфраструктуру</span>
                    </div>
                </div>
                <div className='content__window-img polycamera-img'>
                    <img
                        src={IMGPolycameraX8}
                        alt="Polycamera X8"
                    />
                </div>
                <div className="content__window-text content__window__main-text polycamera-text">
                    <button className='content__window-text-button polycamera-text-button'>Скачать</button>
                    <div
                        className='content__window-text-button-desc content__window__main-text-button-desc  polycamera-text-desc'>
                        <span>Лист данных с характеристиками продукта</span>
                    </div>
                </div>
            </div>

            <div className='content__window polycamera-reasons'>
                <h3 className='polycamera-reasons-title'>Причины выбрать Polycamera</h3>
                <div className='content__window-grid polycamera-reasons-grid'>
                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <SVGBell/>
                        <div className='content__window-grid-item-name'>Большой охват</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <SVGStar/>
                        <div className='content__window-grid-item-name'>Высокое разрешение</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <SVGGroup/>
                        <div className='content__window-grid-item-name'>Экономия на инфраструктере</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Многопользовательность</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Простота использования</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                    <div className='content__window-grid-item polycamera-reasons-grid-item'>
                        <div className='content__window-grid-item-name'>Российская разработка</div>
                        <span className='content__window-grid-item-desc'>Краткое описание ключевого премущества которое необходимо уместить в три строчки, чтобы не улетело</span>
                    </div>

                </div>
            </div>

            <div className='content__window polycamera-img-airport'>
                <img src={IMGAiorport} alt="Airport"/>
            </div>

            <div className='content__window content__window__second polycamera-multifocal'>
                <div className='content__window-text content__window__second-text polycamera-multifocal-text'>
                    <div
                        className='content__window-text-name content__window__second-text-name  polycamera-multifocal-text-name'>
                        <span>Мультифокальная система</span>
                    </div>
                    <h2 className='content__window-text-title content__window__second-text-title polycamera-multifocal-text-title'>
                        Описание мультифокальной системы
                    </h2>
                    <div
                        className='content__window-text-desc content__window__second-text-desc polycamera-multifocal-text-desc'>
                        <span>При увеличении изображения с PTZ камеры, в архив попадет только видимый кадр, с Polycamera не зависимо от увеличения, в архив попадает вся область наблюдения и доступна возможность увеличить нужную область в архиве записи</span>
                    </div>
                </div>
                <div className='polycamera-multifocal-img'>
                    <img
                        src={IMGPolycameraRender}
                        alt="Polycamera X8"
                    />
                </div>
            </div>

            <div className='content__window polycamera-videosensor'>
                <div className="content__window-text">
                    <div className='content__window-text-name polycamera-videosensor-text-name'>
                        <span>Видео сенсор</span>
                    </div>
                    <h2 className='content__window-text-title polycamera-videosensor-text-title'>
                        Высокочувствительные сенсоры
                    </h2>
                    <div className='content__window-text-desc polycamera-videosensor-text-desc'>
                        <span>Камера оснащена самыми современными сенсорами разрешением 4К, имеющие чувствительность превышающую возможности человеческого глаза. Это обеспечивает отличные характеристики наблюдения при слабом освещении </span>
                    </div>
                </div>
                <img
                    src={IMGGroup}
                    alt=""
                    className='group'
                />
                <div className='polycamera-videosensor-img'>
                    <img
                        src={IMGVideoSendor}
                        alt="Video Sensor"
                    />
                </div>
            </div>

            <div className='content__window content__window__second polycamera-lenses'>
                <div className='polycamera-lenses-img'>
                    <img
                        src={IMGlenses}
                        alt="Lenses"
                    />
                </div>
                <div className='content__window__second-text polycamera-lenses-text'>
                    <div
                        className='content__window-text-name content__window__second-text-name polycamera-lenses-text-name'>
                        <span>Оптические линзы</span>
                    </div>
                    <h2 className='content__window-text-title content__window__second-text-title polycamera-lenses-text-title'>
                        Оптика высокого разрешения
                    </h2>
                    <div
                        className='content__window-text-desc content__window__second-text-desc polycamera-lenses-text-desc'>
                        <span>В отличие от PTZ камер, которыми может управлять один человек
                            одновременно, с помощью Polycamera управление может осуществлять
                            неограниченное количество операторов, не мешая друг другу</span>
                    </div>
                </div>
            </div>

            <div className='content__window polycamera-hdr-technology'>
                <img
                    src={IMGGroup2}
                    alt=""
                    className='group2'
                />
                <div className="content__window-text polycamera-hdr-technology-text">
                    <div className='content__window-text-name polycamera-hdr-technology-text-name'>
                        <span>Видео сенсор</span>
                    </div>
                    <h2 className='content__window-text-title polycamera-hdr-technology-text-title'>
                        <span style={{color: '#FF6363'}}>Цветное </span>
                        <span style={{color: '#2BE295'}}>изображение </span>
                        <span style={{color: '#3769EC'}}>ночью</span>
                    </h2>
                    <div className='content__window-text-desc polycamera-hdr-technology-text-desc'>
                        <span>Технология обратной подсветки матрицы, высокий динамический диапазон с возможностью включения HDR режима, все это обеспечивает получение цветного изображения в условиях низкого освещения</span>
                    </div>
                </div>
                <div className="polycamera-hdr-technology-img">
                    <div className="polycamera-hdr-technology-img-item">
                        <div className=''>
                            <img
                                src={IMGHdrDay}
                                alt="HDR Day"
                            />
                        </div>
                        <div
                            className='content__window-text-name polycamera-hdr-technology-text-name polycamera-hdr-technology-img-name'>
                            <span>День</span>
                        </div>
                    </div>
                    <div className="polycamera-hdr-technology-img-item">
                        <div className=''>
                            <img
                                src={IMGHdrTwilight}
                                alt="HDR Twilight"
                            />
                        </div>
                        <div
                            className='content__window-text-name polycamera-hdr-technology-text-name polycamera-hdr-technology-img-name'>
                            <span>Сумерки</span>
                        </div>
                    </div>
                    <div className="polycamera-hdr-technology-img-item">
                        <div className=''>
                            <img
                                src={IMGHdrNight}
                                alt="HDR Night"
                            />
                        </div>
                        <div
                            className='content__window-text-name polycamera-hdr-technology-text-name polycamera-hdr-technology-img-name'>
                            <span>Ночь</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='content__window content__window__second polycamera-camera-control'>
                <div className='polycamera-camera-control-img'>
                    <img
                        src={IMGOperators}
                        alt="Operators"
                    />
                </div>
                <div className='content__window-text content__window__second-text polycamera-camera-control-text'>
                    <div
                        className='content__window-text-name content__window__second-text-name polycamera-camera-control-text-name'>
                        <span>Управление камерой</span>
                    </div>
                    <h2 className='content__window-text-title content__window__second-text-title polycamera-camera-control-text-title'>
                        Неограниченное количество операторов
                    </h2>
                    <div
                        className='content__window-text-desc content__window__second-text-desc polycamera-camera-control-text-desc'>
                        <span>В отличие от PTZ камеры, которой может управлять один человекв единый момент времени, Plycamera позволяет осуществлятьуправление неограниченнму количеству операторов, не мешая друг другу</span>
                    </div>
                </div>
            </div>

            <div className='content__window content__window__second polycamera-archive-video'>
                <div className='content__window-text content__window__second-text polycamera-archive-video-text'>
                    <div
                        className='content__window-text-name content__window__second-text-name polycamera-archive-video-text-name'>
                        <span>Архивное видео</span>
                    </div>
                    <h2 className='content__window-text-title content__window__second-text-title polycamera-archive-video-text-title'>
                        Увеличение изображения в архиве видеозаписи
                    </h2>
                    <div
                        className='content__window-text-desc content__window__second-text-desc polycamera-archive-video-text-desc'>
                        <span>При увеличении изображения с PTZ камеры, в архив попадет только видимый кадр, с Polycamera не зависимо от увеличения, в архив попадает вся область наблюдения и доступна возможность увеличить нужную область в архиве записи</span>
                    </div>
                </div>
                <div className='polycamera-archive-video-img'>
                    <img
                        src={IMGArchiveVideo}
                        alt="Archive Video"
                    />
                </div>
            </div>

            <div className='content__window polycamera-products'>
                <div className="content__window-text polycamera-products-text">
                    <h2 className='content__window-text-title polycamera-products-text-title'>
                        Варианты исполнения Polycamera
                    </h2>
                    <div className='content__window-text-desc polycamera-products-text-desc'>
                    <span>Под требования заказчика, мы можем предложить различные
                        варианты исполнения мультифокальной камеры</span>
                    </div>
                    <div className="polycamera-products-list">
                        <div className="polycamera-products-list-item">
                            <div className="polycamera-products-list-item-img">
                                <img
                                    src={IMGPolycameraProduct}
                                    alt="Polycamera X8"
                                />
                            </div>
                            <div className="polycamera-products-list-item-title">Polycamera <span>Aero</span></div>
                            <div className="polycamera-products-list-item-desc">Наблюдения за взлетно-посадочной полосой
                                и
                                горизонтально протяженных объектов
                            </div>
                            <button>Подробнее</button>
                        </div>
                        <div className="polycamera-products-list-item polycamera-products-list-item">
                            <div className="polycamera-products-list-item-img">
                                <img
                                    src={IMGPolycameraProduct}
                                    alt="Polycamera X8"
                                />
                            </div>
                            <div className="polycamera-products-list-item-title">Polycamera <span>Stadium</span></div>
                            <div className="polycamera-products-list-item-desc">Наблюдение за трибунами стадиона и
                                контроль
                                футбольных болельщиков
                            </div>
                            <button>Подробнее</button>
                        </div>
                        <div className="polycamera-products-list-item">
                            <div className="polycamera-products-list-item-img">
                                <img
                                    src={IMGPolycameraProduct}
                                    alt="Polycamera X8"
                                />
                            </div>
                            <div className="polycamera-products-list-item-title">Polycamera <span>Area</span></div>
                            <div className="polycamera-products-list-item-desc">Наблюдения за протяженными улицами и
                                площадями, развязками и автодорогами
                            </div>
                            <button>Подробнее</button>
                        </div>
                    </div>
                    <div className='polycamera-products-list-details'>
                        Лист данных <a href="/"> Скачать <SVGDownload/></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PolycameraPage;