import React from 'react'
import './Footer.scss'

import {ReactComponent as SVGLogo} from './svg/visys-logo.svg';
import {ReactComponent as SVGWA} from './svg/whatsapp.svg';
import {ReactComponent as SVGTG} from './svg/telegram.svg';


const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer__content'>
                <div className='footer__content-navbar-container'>
                    <span className='footer__content-navbar-container-name'>Продукты</span>
                    <ul>
                        <li>
                            <a className="footer__content-navbar-container-link" href="/polycamera"
                               rel="noopener noreferrer">
                                Мультифокальная Polycamera
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="/omniclient"
                               rel="noopener noreferrer">
                                Видеоплатформа Omni Client
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="/canopus"
                               rel="noopener noreferrer">
                                Видеорегистратор Canopus RMS
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="/norma"
                               rel="noopener noreferrer">
                                Видеодекодер Norma
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                Рабочие станции VSS WS
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                Виртуализация решений
                            </a>
                        </li>
                    </ul>
                </div>

                <div className='footer__content-navbar-container'>
                    <span className='footer__content-navbar-container-name'>Разработка</span>
                    <ul>
                        <li>
                            <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                Разработка полного цикла
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                Разработка веб-приложений
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                Frontend-разработка
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                Backend-разработка
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                Аппаратные решения
                            </a>
                        </li>
                        <li>
                            <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                Аудит процессов разработки
                            </a>
                        </li>
                    </ul>

                    <div className='footer__content-navbar-container'>
                        <span className='footer__content-navbar-container-name'>О компании</span>
                        <ul>
                            <li>
                                <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                    О нас
                                </a>
                            </li>
                            <li>
                                <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                    Стажировка
                                </a>
                            </li>
                            <li>
                                <a className="footer__content-navbar-container-link" href="#" rel="noopener noreferrer">
                                    Контакты
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='footer__content-navbar-container footer__content-contacts'>
                    <SVGLogo/>
                    <span className='footer__content-contacts-phone'>+7 499 113-42-52</span>
                    <span className='footer__content-contacts-mail'>info@visys.ru</span>
                    <div className='footer__content-contacts-socials'>
                        <a href="#" className='social-whatsapp'><SVGWA/></a>
                        <a href="#" className='social-telegram'><SVGTG/></a>
                    </div>
                </div>
                <div className='footer__content-navbar-container footer__content-adress'>
                    <button className=''>Написать письмо</button>
                    <div>
                        <span>105094, Москва</span>
                        <span>Семеновская наб., 2/1, стр.1</span>
                    </div>
                    <div>
                        <span>Часы работы с 10:00 - 19:00</span>
                        <span className="adress-time">Понедельник - Пятница</span>
                    </div>
                </div>
            </div>
            <div className='footer__footer'>
                <span>ООО “Видео Технологии”  ©  2020 — 2025 </span>
                <a href='#'>Политика конфиденциальности</a>
            </div>
        </footer>
    )
}

export default Footer;