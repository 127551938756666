import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Navbar.scss';
import { ReactComponent as SVGChevron } from './svg/chevron-down.svg';

const Navbar: React.FC = () => {
    const location = useLocation();
    const [openMenu, setOpenMenu] = useState<string | null>(null);

    const menuLinks: { [key: string]: string[] } = {
        products: ['/polycamera', '/norma', '/canopus', '/omniclient', '/videowall'],
        development: ['/1', '/2', '/3', '/4', '/5', '/6',],
        aboutCompany: ['/7', '/8', '/9',],
    };

    const isActiveLink = (path: string) => location.pathname === path;

    const isMenuOpen = (menu: string) => {
        return menuLinks[menu].some(path => isActiveLink(path));
    };

    useEffect(() => {
        setOpenMenu(null);
    }, [location.pathname]);


    return (
        <nav className="navbar">
            <ul className="navbar__list">
                <li
                    className={`navbar__item ${isMenuOpen('products') && openMenu ? 'open' : ''}`}
                >
                    <span>Продукты<SVGChevron/></span>
                    <ul className="dropdown-menu">
                        {menuLinks.products.map((path, index) => (
                            <li key={index}>
                                <a
                                    href={path}
                                    onClick={(e) => {
                                        if (isActiveLink(path)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    className={isActiveLink(path) ? 'navbar__item-active' : ''}
                                    rel="noopener noreferrer"
                                >
                                    {path === '/polycamera' ? 'Polycamera X8' : ''}
                                    {path === '/norma' ? 'Видеодекодер Norma S1' : ''}
                                    {path === '/canopus' ? 'Регистратор Canopus NX' : ''}
                                    {path === '/omniclient' ? 'Видеоплатформа Omni Client' : ''}
                                    {path === '/videowall' ? 'Видеостена Videowall' : ''}
                                </a>
                            </li>
                        ))}
                    </ul>
                </li>

                <li
                    className={`navbar__item ${isMenuOpen('development') ? 'open' : ''}`}
                >
                    <span>Разработка<SVGChevron/></span>
                    <ul className="dropdown-menu">
                        {menuLinks.development.map((path, index) => (
                            <li key={index}>
                                <a
                                    href={path}
                                    onClick={(e) => {
                                        if (isActiveLink(path)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    className={isActiveLink(path) ? 'navbar__item-active' : ''}
                                    rel="noopener noreferrer"
                                >
                                    {path === '/1' ? 'Разработка полного цикла' : ''}
                                    {path === '/2' ? 'Разработка веб-приложений' : ''}
                                    {path === '/3' ? 'Frontend-разработка' : ''}
                                    {path === '/4' ? 'Backend-разработка' : ''}
                                    {path === '/5' ? 'Аппаратные решения' : ''}
                                    {path === '/6' ? 'Аудит процессов разработки' : ''}
                                </a>
                            </li>
                        ))}
                    </ul>
                </li>

                <li className="navbar__item">
                    <span>Компетенции</span>
                </li>

                <li
                    className={`navbar__item ${isMenuOpen('aboutCompany') ? 'open' : ''}`}
                >
                    <span>О компании<SVGChevron/></span>
                    <ul className="dropdown-menu">
                        {menuLinks.aboutCompany.map((path, index) => (
                            <li key={index}>
                                <a
                                    href={path}
                                    onClick={(e) => {
                                        if (isActiveLink(path)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    className={isActiveLink(path) ? 'navbar__item-active' : ''}
                                    rel="noopener noreferrer"
                                >
                                    {path === '/7' ? 'О нас' : ''}
                                    {path === '/8' ? 'Стажировка' : ''}
                                    {path === '/9' ? 'Контакты' : ''}
                                </a>
                            </li>
                        ))}
                    </ul>
                </li>


                <li className="navbar__item">
                    <span>Контакты</span>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
