import React, {useState} from 'react';
import './MainPage.scss'
import RenderSubtitle from '../../components/TextAnimation/TextAnimation';


import IMGPolycameraX8 from './image/polycamera_x8_img.png';
import IMGNormaS1 from './image/norma_s1_img.png';
import IMGCanopusServer from './image/canopus-server-img.png';
import IMGOmniclient from './image/omniclient_img.png';
import IMGEllipse from './image/ellipse_img.png';

import {ReactComponent as SVGImg} from './image/icon-svg-img.svg';
import {ReactComponent as SVG4k} from './image/icon-svg-4k.svg';
import {ReactComponent as SVGStar} from './image/icon-svg-star.svg';
import {ReactComponent as SVGArrow} from './image/arrow-icon.svg';
import {ReactComponent as SVGCheckMark} from './image/check-mark-icon.svg';
import {ReactComponent as SVGCheckMarkGreen} from './image/check-mark-green-icon.svg';
import {ReactComponent as SVGArrowRight} from './image/arrow-right-icon.svg';
import {ReactComponent as SVGStadiums} from './image/volleyball.svg';
import {ReactComponent as SVGLogistics} from './image/forklift.svg';
import {ReactComponent as SVGAirports} from './image/plane.svg';
import {ReactComponent as SVGCities} from './image/Cone.svg';
import {ReactComponent as SVGIndustry} from './image/factory.svg';


const MainPage = () => {

    const [active, setActive] = useState('standalone');


    const handleToggle = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target instanceof HTMLElement) {
            if (e.target.classList.contains('canopus-switcher-standalone')) {
                setActive('standalone');
            } else {
                setActive('virtual');
            }
        }
    };

    const faqItems = [
        {
            question: "Сколько стоит мультифокальная камера?",
            answer: "Мультифокальная система Polycamera - это проектное решение, в котором собираются все требования заказчика, производится расчет и выдается предложение. Стоимость одной Polycamera сопоставима со стоимостью десяти камер 4К разрешением."
        },
        {
            question: "Какую площадь я смогу покрыть Поликамерой?",
            answer: "Мы предлагаем различные варианты исполнения камер, для наблюдения за объектами. Доступны модели с охватом до 5 км в ширину и до 2 км в глубину."
        },
        {
            question: "Какое разрешение у Polycamera?",
            answer: "В камере используются высокочувствительные сенсоры разрешением 4К, что позволяет в совокупности получить разрешение более 66 мегапикселей."
        },
        {
            question: "Сколько стоит обслуживать мультифокальные камеры?",
            answer: "Мультифокальные камеры Polycamera не требуют обслуживания, на все камеры расспространяется гарантия в 1 год, с возможностью расширения до 3 лет."
        },
        {
            question: "Поддерживает ли Поликамера аналитику?",
            answer: "Да, мы можем обеспечить классификацию объектов, расспознавание лиц и детектирование номерных знаков или интегрировать в сторонние системы анализа."
        },
        {
            question: "Можно ли увеличивать изображение в записанном архиве?",
            answer: "Да, ключевой особенностью Polycamera является возможность увеличения любой области изображения без потери качества, что позволяет рассмотреть причину инцидента в мельчайших подробностях."
        }
    ];

    const [openItems, setOpenItems] = useState<boolean[]>(faqItems.map(() => false));

    const handleItemClick = (index: number) => {
        setOpenItems((prev) => {
            const updated = [...prev];
            updated[index] = !updated[index];
            return updated;
        });
    };


    return (
        <div className='content'>
            <img
                src={IMGEllipse}
                alt=""
                className='ellipse ellipse-1'
            />
            <img
                src={IMGEllipse}
                alt=""
                className='ellipse ellipse-2'
            />
            <img
                src={IMGEllipse}
                alt=""
                className='ellipse ellipse-3'
            />
            <img
                src={IMGEllipse}
                alt=""
                className='ellipse ellipse-4'
            />

            <div className='content__window content__window__main'>
                <div className="content__window-text">
                    <div className='content__window-text-name content__window__main-text-name'>
                        <span>ДЕТАЛЬНЫЙ ВЗГЛЯД НА ВАЖНЫЕ ВЕЩИ</span>
                    </div>
                    <RenderSubtitle/>
                    <div className='content__window-text-desc content__window__main-text-desc'>
                    <span>Наблюдение за большими территориями с высоким качеством,
                    как днём, так и ночью без дополнительного освещения</span>
                    </div>
                    <button className='content__window-text-button button'>Подробнее</button>
                    <div className='content__window-text-button-desc content__window__main-text-button-desc'>
                        <span>Скачать презентацию наших решений</span>
                    </div>
                </div>
            </div>

            <div className='content__window main-polycamera'>
                <div className="content__window-text main-polycamera-text">
                    <div className='content__window-text-name'>
                        <span>POLYCAMERA X8</span>
                    </div>
                    <h2 className='content__window-text-title main-polycamera-text-title'>
                        Мультифокальная панорамная камера
                    </h2>
                    <div className='content__window-text-desc main-polycamera-text-desc'>
                        <span>Решение для наблюдения за большими площадями из единой точки, с высоким качеством изображения и низкими затратами на инфраструктуру</span>
                    </div>
                </div>
                <div className='content__window-img'>
                    <img
                        src={IMGPolycameraX8}
                        alt="Polycamera X8"
                    />
                </div>
                <div className='content__window-grid'>
                    <div className='content__window-grid-item'>
                        <SVGImg/>
                        <div className='content__window-grid-item-name'>Большой охват</div>
                        <span className='content__window-grid-item-desc'>Одна установка позволяет охватить протяженный участок и вести наблюдение за территорией заменяя 10 камер высокого разрешения</span>
                    </div>
                    <div className='content__window-grid-item'>
                        <SVG4k/>
                        <div className='content__window-grid-item-name'>Высокое качество</div>
                        <span className='content__window-grid-item-desc'>Высокое разрешение изображения в любой точке наблюдения в режиме реального времени и архиве видеозаписи</span>
                    </div>
                    <div className='content__window-grid-item'>
                        <SVGStar/>
                        <div className='content__window-grid-item-name'>Экономия на инфраструктере</div>
                        <span className='content__window-grid-item-desc'>Мультифокальная камера позволяет с экономить на кабельной инфраструктуре и местах установки</span>
                    </div>
                </div>
            </div>

            <div className='content__window content__window__second norma-s1'>
                <div className='content__window-text content__window__second-text'>
                    <div className='content__window-text-name content__window__second-text-name norma-s1-name'>
                        <span>NORMA S1</span>
                    </div>
                    <h2 className='content__window-text-title content__window__second-text-title'>
                        Компактный декодер для построения видеостены
                    </h2>
                    <div className='content__window-text-desc content__window__second-text-desc norma-s1-text-desc'>
                        <span>Компактный декодер позволяет оперативно запустить на мониторных панелях отображение видеопотоков, с возможностью управления через Веб интерфейс</span>
                    </div>
                </div>
                <div className='norma-s1-img'>
                    <img
                        src={IMGNormaS1}
                        alt="Norma S1"
                    />
                </div>
            </div>

            <div className='content__window omniclient'>
                <div className="content__window-text omniclient-text">
                    <div className='content__window-text-name omniclient-text-name'>
                        <span>OMNI CLIENT</span>
                    </div>
                    <h2 className='content__window-text-title omniclient-text-title'>
                        Платформа управления видеонаблюдением
                    </h2>
                    <div className='content__window-text-desc omniclient-text-desc'>
                        <span>Программный комплекс для построенения системы видеонаблюдения на предприятии заказчика</span>
                    </div>
                    <button className='button omniclient-text-button'>Подробнее</button>
                </div>
                <div className=''>
                    <img
                        src={IMGOmniclient}
                        alt="Omniclient"
                        className='content__window__second-img-item'
                    />
                </div>
            </div>

            <div className='content__window content__window__second applicability'>
                <div className='content__window__second-img clipboard-history-img applicability-img'>
                    <div className='applicability-icon-group'>
                        <div className='icon icon-1'>
                            <div className='applicability-icon'>
                                <SVGStadiums/>
                                <span>Стадионы</span>
                            </div>
                        </div>
                        <div className='icon icon-2'>
                            <div className='applicability-icon'>
                                <SVGLogistics/>
                                <span>Логистика</span>
                            </div>
                        </div>
                        <div className='icon icon-3'>
                            <div className='applicability-icon'>
                                <SVGAirports/>
                                <span>Аэропорты</span>
                            </div>
                        </div>
                        <div className='icon icon-4'>
                            <div className='applicability-icon'>
                                <SVGCities/>
                                <span>Города</span>
                            </div>
                        </div>
                        <div className='icon icon-5'>
                            <div className='applicability-icon'>
                                <SVGIndustry/>
                                <span>Индустрия</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className='content__window-text content__window__second-text clipboard-history-text applicability-text'>
                    <div
                        className='content__window-text-name content__window__second-text-name clipboard-history-name'>
                        <span>ПРИМЕНЕНИЕ</span>
                    </div>
                    <h2 className='content__window-text-title content__window__second-text-title'>
                        Область применения
                    </h2>
                    <div className='content__window-text-desc content__window__second-text-desc applicability-text-desc'>
                        <span>Высокое качество и надежность наших продуктов
                        позволяет решать задачи клиента на критически
                        важных объектах где требуется непрерывность,
                        безотказность и производительность системы </span>
                    </div>
                </div>
            </div>


            <div className='content__window content__window__second canopus-server'>
                <div className='content__window__second-img canopus-server-img'>
                    <img
                        src={IMGCanopusServer}
                        alt="Canopus Recording Derver"
                        className='content__window__second-img-item'
                    />
                </div>
                <div className='content__window-text content__window__second-text canopus-server-text'>
                    <div className='content__window-text-name content__window__second-text-name canopus-server-name'>
                        <span>CANOPUS RECORDING SERVER</span>
                    </div>
                    <h2 className='content__window-text-title content__window__second-text-title canopus-server-text-title'>
                        Высокопроизводительный сервер записи видеопотоков
                    </h2>
                    <div
                        className='content__window-text-desc content__window__second-text-desc canopus-server-text-desc'>
                            <span>Видеорегистратор с возможностью записи 200 видеопотоков,
                                обладает аппаратным RAID массивом и различными уровнями
                                избыточности. Резервированый блок питания, эффективная
                                система охлаждения и работа под ОС Linux - обеспечивает
                                высокую надежность и непрерывность работы</span>
                    </div>
                </div>
            </div>

            <div className='content__window canopus'>
                <div className="content__window-text">
                    <h2 className='content__window-text-title canopus-text-title'>
                        Варианты исполнения Canopus Series
                    </h2>
                    <div className='content__window-text-desc canopus-text-desc'>
                        <span>Для гибкого решения задач клиента, мы предлагаем несколько вариантов
                        исполнения видеорегистратора. Готовое решение Standalone или виртуализация
                        с поддержкой HA Cluster</span>
                    </div>
                    <div className='canopus-switcher'>
                        <div
                            className={`canopus-switcher-standalone switcher-toggle ${active === 'standalone' ? 'active' : ''}`}
                            onClick={handleToggle}
                        >
                            Standalone
                        </div>
                        <div
                            className={`canopus-switcher-virtual switcher-toggle ${active === 'virtual' ? 'active' : ''}`}
                            onClick={handleToggle}
                        >
                            Virtual
                        </div>
                        <div
                            className="canopus-switcher-toggle-background"
                            style={{
                                transform: active === 'virtual' ? 'translateX(110px)' : 'translateX(0)',
                                width: active === 'virtual' ? '92px' : '102px'
                            }}
                        />
                    </div>
                </div>

                <div className='canopus-products'>

                    <div className='canopus-products-panel'>
                        <div className='canopus-products-panel-name'>Регистратор Canopus S</div>
                        <div className='canopus-products-panel-desc'>Запись до 100 видеоканалов 4K</div>
                        <div className='hr'/>
                        <div className='canopus-products-panel-version'><span>Standard version</span></div>
                        <div className='canopus-products-panel-list'>
                            <div className='canopus-products-panel-list-desc'>Основные функции</div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>Запись до 100 каналов 4К</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>Запись по детекции движения</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>Резервирование записи</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>Onvif Profile S/T</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>RAID 5, 6, 50, 60</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>OC Linux</span>
                            </div>
                        </div>
                        <button>Подробнее</button>
                    </div>


                    <div className='canopus-products-panel'>
                        <div className='canopus-products-panel-name'>Регистратор Canopus E</div>
                        <div className='canopus-products-panel-desc'>Запись до 200 видеоканалов 4K</div>
                        <div className='hr'/>
                        <div className='canopus-products-panel-version'><span>Enterprise version</span></div>
                        <div className='canopus-products-panel-list'>
                            <div className='canopus-products-panel-list-desc'>Основные функции</div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMarkGreen/>
                                <span>Запись до 200 каналов 4К</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMarkGreen/>
                                <span>Запись по детекции движения</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMarkGreen/>
                                <span>Onvif Profile S/T</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMarkGreen/>
                                <span>Поддержка дисков SAS</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMarkGreen/>
                                <span>RAID 5, 6, 50, 60, HotSpare</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMarkGreen/>
                                <span>OC Linus или Astra Linux</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMarkGreen/>
                                <span>Расширенная гарантия до 36 мес.</span>
                            </div>
                        </div>
                        <button>Подробнее</button>
                    </div>

                    <div className='canopus-products-panel'>
                        <div className='canopus-products-panel-name'>Регистратор Canopus V</div>
                        <div className='canopus-products-panel-desc'>Запись до 100 видеоканалов 4K</div>
                        <div className='hr'/>
                        <div className='canopus-products-panel-version'><span>Virtual version</span></div>
                        <div className='canopus-products-panel-list'>
                            <div className='canopus-products-panel-list-desc'>Основные функции</div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>Запись до 100 каналов на одну ноду</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>Запись по детекции движения</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>Onvif Profile S/T</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>VMware, ProxMox, OpenNebula</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>Расширенная гарантия</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>OC Linux или Astra Linux</span>
                            </div>
                            <div className='canopus-products-panel-list-item'>
                                <SVGCheckMark/>
                                <span>HA Cluster</span>
                            </div>
                        </div>
                        <button>Подробнее</button>
                    </div>
                </div>
                <div className='canopus-details'>
                    Узнать подробности <a href="/"> Перейти <SVGArrowRight/></a>
                </div>
            </div>

            <div className='faqs'>
                <div className='content__window faqs-content'>
                    <div className="content__window-text faqs-text">
                        <h2 className='content__window-text-title faqs-title'>
                            FAQs
                        </h2>
                        <div className='content__window-text-desc faqs-desc'>
                            <span>Ответы на часто задаваемые вопросы</span>
                        </div>
                        <div className="faqs-list">
                            {faqItems.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div
                                        className={`faqs-list-item ${openItems[index] ? 'open' : ''}`}
                                        onClick={() => handleItemClick(index)}
                                    >
                                        <span>{item.question}</span>
                                        <SVGArrow className="SVGArrow"/>
                                    </div>
                                    <div className={`faqs-answer ${openItems[index] ? 'visible' : ''}`}>
                                        {item.answer}
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MainPage;