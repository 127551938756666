import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import MainPage from './pages/MainPage';
import './App.scss';
import PolycameraPage from './pages/PolycameraPage';

function App() {
  return (

    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/polycamera" element={<PolycameraPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
